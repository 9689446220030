import { Divider } from "@nextui-org/react";
import { SearchInputProps } from "../types/reportTypes";

export default function SearchInput({ inputData }: SearchInputProps) {
  return (
    <>
      <Divider />
      <div className="mt-5">
        <h2 className="text-xl mb-2">Based off the following input:</h2>
        {inputData.bigTextBox && inputData.bigTextBox.length > 0 && (
          <p className="ml-4">{inputData.bigTextBox}</p>
        )}
      </div>
    </>
  );
}
