import { useEffect, useState } from "react";
import {
  ActiveElement,
  ChartEvent,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { motion } from "framer-motion";
import { Radar } from "react-chartjs-2";
import { Card, CardFooter, CardHeader, Divider, Link } from "@nextui-org/react";
import { useTheme } from "next-themes";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const spring = {
  type: "spring",
  stiffness: 400,
  damping: 20,
};

interface FeatureTeacher {
  coverage: number;
  feature: string;
  number: string;
  title: string;
  inventors: string[];
  quote: string;
  url: string;
}

interface RadarGraph {
  summary: {
    feature_teachers: { [key: string]: FeatureTeacher };
    X_matches: { [key: string]: XMatch };
    X_radar: number[];
    teacher_radar: number[];
  };
}

interface XMatch {
  feature: string;
  number: string;
  title: string;
  inventors: string[];
  quote: string;
  url: string;
}

interface SelectedPoint {
  dataset: number | null;
  index: number | null;
}

export default function RadarGraph(radarGraph: RadarGraph) {
  const [selectedPoint, setSelectedPoint] = useState<SelectedPoint>({ dataset: null, index: null });
  const [chartOptions, setChartOptions] = useState({});
  const { theme } = useTheme();

  useEffect(() => {
    const baseOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        r: {
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            display: true,
            showLabelBackdrop: true,
            backdropColor: theme === "dark" ? "black" : "white",
            color: theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)",
          },
          font: {
            size: 8,
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            color: "rgba(0, 0, 0, 0.8)",
          },
        },
        tooltip: {
          callbacks: {
            title: (context: TooltipItem<"radar">[]) => {
              const index = context[0].dataIndex;
              const datasetIndex = context[0].datasetIndex;
              return getTitle(datasetIndex, index);
            },
            label: (context: TooltipItem<"radar">) => {
              return `Score: ${context.formattedValue}`;
            },
          },
        },
      },

      onClick: (_event: ChartEvent, elements: ActiveElement[]) => {
        if (elements.length > 0) {
          const { datasetIndex, index } = elements[0];
          setSelectedPoint((prevPoint) =>
            prevPoint.dataset === datasetIndex && prevPoint.index === index
              ? { dataset: null, index: null }
              : { dataset: datasetIndex, index }
          );
        } else {
          setSelectedPoint({ dataset: null, index: null });
        }
      },
    };

    if (theme != undefined && theme == "dark") {
      setChartOptions({
        ...baseOptions,
        scales: {
          ...baseOptions.scales,
          r: {
            ...baseOptions.scales.r,
            grid: {
              color: "rgba(255, 255, 255, 0.2)",
            },
            angleLines: {
              color: "rgba(255, 255, 255, 0.2)",
            },
            pointLabels: {
              color: "rgba(255, 255, 255, 0.7)",
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: "rgba(255, 255, 255, 0.7)",
            },
          },
        },
      });
    } else {
      setChartOptions(baseOptions);
    }
  }, [theme]);

  const getTitle = (dataset: number | null, index: number | null): string => {
    if (dataset === null || index === null) return "";
    const data = dataset === 0 ? radarGraph.summary.feature_teachers : radarGraph.summary.X_matches;
    return (data[index.toString()] as FeatureTeacher | XMatch)?.title || "";
  };

  const getPatentLink = (dataset: number | null, index: number | null): string => {
    if (dataset === null || index === null) return "";
    const data = dataset === 0 ? radarGraph.summary.feature_teachers : radarGraph.summary.X_matches;
    return (data[index.toString()] as FeatureTeacher | XMatch)?.url || "";
  };

  const getPatentNumber = (dataset: number | null, index: number | null): string => {
    if (dataset === null || index === null) return "";
    const data = dataset === 0 ? radarGraph.summary.feature_teachers : radarGraph.summary.X_matches;
    return (data[index.toString()] as FeatureTeacher | XMatch)?.number || "";
  };

  const data = {
    labels: Object.keys(radarGraph.summary.feature_teachers).map(
      (key) => `Feature ${parseInt(key) + 1}`
    ),
    datasets: [
      {
        label: "Closest Match",
        data: radarGraph.summary.teacher_radar,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132, 0.5)",
        pointBackgroundColor: "rgb(255, 99, 132, 0.5)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
      {
        label: "Closest Single Patent",
        data: radarGraph.summary.X_radar,
        backgroundColor: "rgba(100, 210, 203, 0.5)",
        borderColor: "rgb(100, 99, 132, 0.5)",
        pointBackgroundColor: "rgb(100, 99, 132, 0.5)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(100, 99, 132)",
      },
    ],
  };

  const paragraphVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div className="flex flex-col lg:flex-row justify-around items-center max-w-[1100px] mx-auto p-5">
      <motion.div
        layout
        transition={spring}
        className="w-full max-w-[400px] min-w-[300px] aspect-square"
      >
        <Radar data={data} options={chartOptions} />
      </motion.div>
      {selectedPoint.index !== null && selectedPoint.dataset !== null && (
        <motion.div
          className="text-center max-w-sm mt-8 lg:mb-0"
          variants={paragraphVariants}
          initial="hidden"
          animate="visible"
        >
          <Card>
            <CardHeader className="justify-center">
              <p className="text-lg">{getTitle(selectedPoint.dataset, selectedPoint.index)}</p>
            </CardHeader>
            <Divider />
            <CardFooter className="justify-center">
              <Link
                isExternal
                showAnchorIcon
                href={getPatentLink(selectedPoint.dataset, selectedPoint.index)}
              >
                {getPatentNumber(selectedPoint.dataset, selectedPoint.index)}
              </Link>
            </CardFooter>
          </Card>
        </motion.div>
      )}
    </div>
  );
}
