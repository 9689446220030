import { MainAndSubcollection } from "../types/reportResultsTypes";
import { getAllData, getAllDocumentsFromMainAndSubcollection } from "../lib/firestoreOperations";

const collectionName = "searches";
const inputCollectionName = "input_search";

export const getAllSearches = async () => {
  return await getAllData(collectionName);
};

export const getAllSearchesAndSubDocuments = async (
  inputSearchId: string
): Promise<MainAndSubcollection | undefined> => {
  return await getAllDocumentsFromMainAndSubcollection(
    collectionName,
    "input_search_id",
    inputSearchId,
    "patents"
  );
};
export const getAllInputSearchesAndSubDocuments = async (
  inputSearchId: string
): Promise<MainAndSubcollection | undefined> => {
  return await getAllDocumentsFromMainAndSubcollection(
    inputCollectionName,
    "id",
    inputSearchId,
    "patents"
  );
};
