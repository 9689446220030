import Head from "next/head";
import { FullLogo } from "../components/icons";
import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import VantaLink from "../components/vantaLink";

const Page = () => {
  const navigate = useNavigate();

  function searchClick() {
    navigate("/search");
  }
  return (
    <div className="flex items-center justify-center mt-32">
      <Head>
        <title>IntelliPat.ai</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="flex flex-col items-center justify-center px-10 text-center h-full">
        <div id="center" className="flex flex-col items-end">
          <FullLogo width={563} height={140} />
          <h1 className="text-xl font-bold mr-[5%]">AI-Powered Patent Solutions</h1>
        </div>
        <Button
          className="m-14"
          radius="lg"
          size="lg"
          onClick={searchClick}
          aria-label="Search Button"
        >
          NEW NOVELTY SEARCH
        </Button>
        <VantaLink />
      </div>
    </div>
  );
};

export default Page;
