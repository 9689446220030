import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button, Modal, ModalContent, ModalFooter, ModalHeader } from "@nextui-org/react";
import { auth } from "../../lib/firebase-config";
import { getUserByAuthId, updateUser } from "../../services/userService";
import { User } from "../../types/userType";
import { RiDeleteBin6Line } from "react-icons/ri";
import { post } from "aws-amplify/api";

export default function Settings(props: { isAuthenticated: boolean }) {
  type DeleteAccountResponse = {
    success: boolean;
    message: string;
  };

  const navigate = useNavigate();

  const [user, setUser] = useState<User>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [deleteConfirmLoading, setDeleteConfirmLoading] = useState<boolean>(false);
  const [isDeleteConfirmValid, setIsDeleteConfirmValid] = useState<boolean>(true);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user && props.isAuthenticated) {
        await getUserData(user.uid);
      } else {
        navigate("/");
      }
    });
  }, []);

  async function getUserData(uid: string) {
    await getUserByAuthId(uid).then((doc) => {
      setUser(doc as User);
      setName(doc?.name || "");
      setEmail(doc?.email || "");
    });
  }

  async function handleUpdate() {
    setUpdateLoading(true);
    if (user && user.id) {
      try {
        await updateUser({
          id: user.id,
          data: {
            name,
            // phone,
            // address,
            // city,
            // state,
            // country,
            // zip,
          },
        });
        await getUserData(user.auth_id);
        setUpdateLoading(false);
      } catch (error) {
        console.error("Error updating document: ", error);
        setUpdateLoading(false);
      }
    }
  }
  async function handleDeleteAccount() {
    setDeleteConfirmLoading(true);
    if (deleteConfirmation === "delete" && user && user.id) {
      try {
        await getUserData(user.auth_id);
        console.log("USER: ", user);
        const restOperation = post({
          apiName: "intellipatGeneralApi",
          path: "/delete-account-email",
          options: {
            body: {
              email: user.email,
              auth_id: user.auth_id,
            },
          },
        });
        const { body } = await restOperation.response;
        const responseData = (await body.json()) as DeleteAccountResponse;
        if (responseData && responseData.success) {
          await updateUser({
            id: user.id,
            data: { deleteAccount: true },
          });
          await getUserData(user.auth_id);
          setIsDeleteModalOpen(false);
          setDeleteConfirmLoading(false);
        }
      } catch (error) {
        console.error("Error Deleting user: ", error);
        setIsDeleteModalOpen(false);
        setDeleteConfirmLoading(false);
      }
    } else {
      setIsDeleteConfirmValid(false);
      setDeleteConfirmLoading(false);
    }
  }

  return (
    <section className="m-auto">
      <Modal
        backdrop="blur"
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setDeleteConfirmation("");
        }}
      >
        <ModalContent>
          <>
            <ModalHeader className="px-6">
              {user && user.deleteAccount
                ? "You have already initiated a delete request. Would you like to request again ?"
                : "Are you sure you want to delete your account?"}
            </ModalHeader>
            <div className="px-6">
              <p className="mb-4">
                Deleting your account is a permanent action and cannot be undone. Your data will be
                removed from our servers, and the process may take up to one week to complete.
              </p>
              <Input
                classNames={{
                  label: "text-base",
                  input: "text-base italic ",
                }}
                label="Type 'delete' to confirm"
                placeholder="delete"
                value={deleteConfirmation}
                isInvalid={!isDeleteConfirmValid}
                errorMessage="Please type delete to confirm deletion"
                onChange={(e) => setDeleteConfirmation(e.target.value)}
              />
            </div>
            <ModalFooter>
              <Button
                color="default"
                onPress={() => {
                  setIsDeleteModalOpen(false);
                  setDeleteConfirmation("");
                }}
              >
                Cancel
              </Button>
              <Button
                isLoading={deleteConfirmLoading}
                className="font-bold text-danger"
                onPress={handleDeleteAccount}
              >
                Confirm Delete
              </Button>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
      <h1 className="text-3xl p-5 font-bold flex justify-center">User Settings</h1>
      {user && (
        <div className="flex flex-col items-center gap-2">
          <div className="flex justify-center items-center gap-2">
            <Input
              classNames={{
                label: "text-base",
                input: "text-base",
              }}
              label="Name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              classNames={{
                label: "text-base",
                input: "text-base",
              }}
              label="Email"
              placeholder="Email"
              value={email}
              disabled
            />
          </div>
          <div className="mt-5 mb-2">
            <Button
              isLoading={updateLoading}
              className="text-base dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </div>
          <div className="flex flex-col gap-2 mt-5 mb-2">
            {user && user.deleteAccount ? (
              <div className="font-bold text-danger"> Account deletion requested</div>
            ) : (
              ""
            )}
            <Button
              className="font-bold text-danger"
              endContent={<RiDeleteBin6Line />}
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Delete Account
            </Button>
          </div>
        </div>
      )}
    </section>
  );
}
