import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { LoadInventionProps } from "../types/searchFormTypes";

export default function LoadInvention({
  inventions,
  onInventionSelect,
  selectedInventionId,
}: LoadInventionProps) {
  const sortedInventions = [...inventions].sort((a, b) => {
    const titleA = a.titleMatter || "";
    const titleB = b.titleMatter || "";
    const numA = parseInt(titleA.match(/\d+/)?.[0] || "0");
    const numB = parseInt(titleB.match(/\d+/)?.[0] || "0");
    if (numA !== numB) {
      return numA - numB;
    }
    return titleA.localeCompare(titleB);
  });

  return (
    <Autocomplete
      label="Load Invention"
      size="sm"
      selectedKey={selectedInventionId}
      onSelectionChange={(id) => onInventionSelect(String(id))}
      radius="lg"
      variant="bordered"
      inputProps={{
        classNames: {
          label:
            "text-base text-default-foreground group-data-[filled-within=true]:text-default-foreground",
          input: "text-base",
        },
      }}
    >
      {sortedInventions.map((invention) => (
        <AutocompleteItem
          classNames={{
            title: "text-base",
          }}
          key={invention.id}
          textValue={invention.titleMatter || "-----"}
          value={invention.id}
        >
          {invention.titleMatter ? invention.titleMatter : "-----"}
        </AutocompleteItem>
      ))}
    </Autocomplete>
  );
}
