import { Link } from "@nextui-org/react";

export default function VantaLink() {
  return (
    <div className="">
      <Link
        size="lg"
        isExternal
        showAnchorIcon
        href="https://app.vanta.com/intellipat/trust/zn93oidwns646cfppj2pn0"
      >
        Trust Center
      </Link>
    </div>
  );
}
