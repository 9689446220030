import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { CgOptions } from "react-icons/cg";
import { SearchOptionsProps } from "../types/searchFormTypes";
import { IoIosSave } from "react-icons/io";
import LoadInvention from "./loadInvention";

export default function SearchOptions(props: SearchOptionsProps) {
  return (
    <Dropdown>
      <DropdownTrigger>
        <Button size="lg" startContent={<CgOptions size={25} />}>
          Options
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Search Options" closeOnSelect={false}>
        <DropdownItem key="load" closeOnSelect={false}>
          {props.inventions && (
            <div>
              <LoadInvention
                inventions={props.inventions}
                onInventionSelect={props.setSelectedInventionId}
                selectedInventionId={props.selectedInventionId}
              />
            </div>
          )}
        </DropdownItem>
        <DropdownItem key="save" onClick={props.saveInvention}>
          <div className="flex gap-2">
            Save Invention <IoIosSave size={20} />
          </div>
        </DropdownItem>
        <DropdownItem
          classNames={{ title: "text-base" }}
          key="clear"
          onClick={props.clearEntryFields}
        >
          Clear Entry Fields
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
