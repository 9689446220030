import { Progress } from "@nextui-org/react";

interface ProgressBarProps {
  featureScore: number;
  translateX?: string;
  transform?: boolean;
}

export default function ProgressBar({ featureScore, translateX, transform }: ProgressBarProps) {
  const getGradientClass = (value: number) => {
    if (transform) {
      value = 100 - value;
    }
    if (value <= 33) {
      return "bg-gradient-to-r from-[#EA4228] to-[#EA4228]";
      // return "bg-gradient-to-r from-[#737373] to-[#737373]";
    } else if (value <= 66) {
      return "bg-gradient-to-r from-[#F5CD19] to-[#F5CD19]";
      // return "bg-gradient-to-r from-[#9D7777] to-[#9D7777]";
    } else {
      return "bg-gradient-to-r from-[#5BE12C] to-[#5BE12C]";
      // return "bg-gradient-to-r from-[#B67070] to-[#B67070]";
    }
  };

  return (
    <div className={`${transform ? "transform scale-[-1]" : ""}`}>
      <Progress
        size="lg"
        radius="full"
        classNames={{
          track: `drop-shadow-md border border-default`,
          indicator: `${getGradientClass(featureScore)}`,
          label: "tracking-wider font-medium text-default-600",
        }}
        value={featureScore}
        formatOptions={{ style: "decimal" }}
        aria-label={`Progress indicator with a value of ${featureScore}`}
      />
      <div
        className={`flex items-center mt-[-25px] relative ${
          translateX ? translateX : "translate-x-[-10%]"
        }`}
      >
        <span
          className={`font-bold text-dark dark:bg-black bg-white rounded-full border-2 dark:border-white border-black flex justify-center w-[30px] h-[30px] pb-[1px] items-center  ${
            transform ? "transform scale-[-1]" : ""
          }`}
        >
          {featureScore}
        </span>
      </div>
    </div>
  );
}
