import { Button } from "@nextui-org/button";
import { TbError404 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center mt-20 text-center">
      <TbError404 size={150} />
      <h1 className="font-bold text-2xl mb-3">Sorry, this page isn't available.</h1>
      <p>The link you followed may be broken, or the page may have been removed.</p>
      <Button
        onPress={() => {
          navigate("/");
        }}
        className="text-base dark:bg-[#d1d5db] dark:text-black bg-[#1e293b] text-white my-6"
      >
        Go Back Home
      </Button>
    </div>
  );
}
