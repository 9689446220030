import {
  addDocument,
  getAllDataByUserEmail,
  getDocSnapshot,
  profileUser,
  updateDocument,
  getServerTimestampDB,
  getDocRef,
  getAllDocumentsFromMainAndSubcollection,
  updateDocumentRef,
  getCollectionByRef,
  getDocRefByRef,
  getAllDataByUserId,
} from "../lib/firestoreOperations";
import { getAllSearchesAndSubDocuments } from "./searchService";
import { getUserByAuthId } from "./userService";
import { Item } from "../types/reportResultsTypes";

const collectionName = "input_search";

export const getServerTimestamp = () => getServerTimestampDB();

export const getAllInputSearch = async (authUser, pageSize, lastVisible) => {
  const userData = await profileUser(authUser);
  if (userData) {
    let inputSearches = [] as Item[];
    const { items, totalCount } = await getAllDataByUserId(
      collectionName,
      userData.auth_id,
      pageSize,
      lastVisible
    );
    for (const searchItem of items) {
      const docs = await getAllSearchesAndSubDocuments(searchItem.id);
      let input = { ...searchItem, results: {} };
      if (docs) {
        input.results = {
          ...docs.mainDocument?.data,
          ...docs.subcollectionDocuments.map((doc) => doc.data),
        };
      }
      inputSearches.push(input as Item);
    }
    const lastVisibleDoc = items.length ? items[items.length - 1].created_at : null;
    return { Reports: inputSearches, totalCount, lastVisibleDoc };
  }
  return { Reports: [], totalCount: 0, lastVisibleDoc: null };
};

export const createInputSearch = async ({
  data,
  returnRef = false,
}: {
  data: any;
  returnRef?: boolean;
}) => {
  const result = await addDocument(collectionName, data);
  const documentData = { id: result.id, ...getDocSnapshot(result) };
  updateDocument(collectionName, result.id, documentData);
  return returnRef ? result : documentData;
};

export const updateInputSearch = async ({ id, data }: { id: string; data: any }) => {
  await updateDocument(collectionName, id, data);
  return { id, ...data };
};

export const saveUserInvention = async ({ data }: { data: any }) => {
  const userDoc = await getUserByAuthId(data.user_id);
  const userDocRef = getDocRef("users", userDoc.id);
  const result = await addDocument("savedInventions", data, userDocRef);
  return result;
};

interface SubcollectionDocument {
  id: string;
}

export const getAllSavedInventions = async (userId: string) => {
  const userDocs = await getAllDocumentsFromMainAndSubcollection(
    "users",
    "auth_id",
    userId,
    "savedInventions"
  );
  const savedInvention: SubcollectionDocument[] = userDocs.subcollectionDocuments;
  return savedInvention;
};

export const updateUserInvention = async ({
  docId,
  data,
}: {
  docId: string | undefined;
  data: any;
}) => {
  const userDoc = await getUserByAuthId(data.user_id);
  const userDocRef = getDocRef("users", userDoc.id);
  const savedInventionsDoc = getCollectionByRef(userDocRef, "savedInventions");
  const savedInventionsDocRef = getDocRefByRef(savedInventionsDoc, docId);
  const result = await updateDocumentRef(savedInventionsDocRef, data);
  return result;
};
