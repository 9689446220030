//HEADER TYPES
export interface ReportHeaderProps {
  noveltyScore: number;
  titleMatter: string;
}

export interface FeatureListProps {
  primaryFeatures: string[];
  secondaryFeatures?: string[];
}

//PRIOR ART TABLE SECTION TYPES
export interface ContentItem {
  paragraphNumber: string;
  detailForFeature: string;
}

export interface DetailItem {
  feature: string;
  content: ContentItem[];
}

export interface PriorArtEntry {
  coverage: number;
  patentNumber: string;
  patentLink?: string;
  familyNumber: string;
  title: string;
  inventors: string;
  abstract: string;
  match: string;
  detail: DetailItem[];
}

export interface PriorArtTableProps {
  priorArtTableData: PriorArtEntry[];
}

export interface AdditionalDetailsProps {
  patentDetail: PriorArtEntry;
}

//FEATURE SYNOPSIS TABLE SECTION TYPES

export interface FeatureDetail {
  feature: string;
  coverage: number;
  patentNumber: string;
  familyNumber: string;
  title: string;
  inventors: string[];
  patentLink: string;
  detail: string[];
}

export interface SynopsisDetailItem {
  similarity: number;
  content: string;
}

interface FeatureDetailV2 extends Omit<FeatureDetail, "detail"> {
  detail: SynopsisDetailItem[];
}

export function isFeatureDetailV2(data: any): data is FeatureDetailV2 {
  return data.detail.length > 0 && typeof data.detail[0] === "object";
}

export interface SecondaryFeatureTableProps {
  secondaryFeatureTable: (FeatureDetailV2 | FeatureDetail)[];
}

export interface PrimaryFeatureTableProps {
  primaryFeatureTable: (FeatureDetailV2 | FeatureDetail)[];
}

export interface AllFeatureTableProps
  extends PrimaryFeatureTableProps,
    SecondaryFeatureTableProps {}

export interface AdditionalFeaturesProps {
  featureDetail: FeatureDetailV2 | FeatureDetail;
}

type PriorArtTable = PriorArtEntry[];

export interface ReportData {
  noveltyScore: string;
  featureCoverage: number[];
  secondaryFeatureCoverage?: number[];
  primaryFeatures: string[];
  secondaryFeatures?: string[];
  priorArtTable: PriorArtTable;
  primaryFeatureTable: (FeatureDetail | FeatureDetailV2)[];
  secondaryFeatureTable?: (FeatureDetail | FeatureDetailV2)[];
}

export interface SearchInputProps {
  inputData: {
    technology_area: String;
    invention_type: String;
    titleMatter: String;
    bigTextBox: String;
    primary: Array<Array<String>>;
    secondary: Array<String>;
    email: String;
  };
}
