import { useState, useEffect } from "react";
import ReportResultsTable from "../../../components/ReportResultsTable";
import { Button, Link } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { CognitoUser } from "../../../types/cognitoUserType";

export default function ReportList(props: {
  userIdentity?: CognitoUser;
  isAuthenticated: boolean;
}) {
  const navigate = useNavigate();

  const [user] = useState<CognitoUser | undefined>(props.userIdentity);
  const [uuid] = useState<string>("");
  useEffect(() => {
    props.isAuthenticated !== true && navigate("/");
  }, []);

  return (
    <>
      <h1 className="text-3xl p-5 font-bold flex justify-center">Reports</h1>
      {user && <ReportResultsTable key={uuid} currentAuthUser={user} />}
      <div className="flex justify-center my-6 p-2">
        <Link isExternal href="https://forms.gle/3NN1ZoPqh8CyZZZW7">
          <Button
            className="text-white dark:bg-[#d1d5db] dark:text-black bg-[#1e293b]"
            radius="lg"
            size="lg"
          >
            We'd Love To Get Feedback From You!
          </Button>
        </Link>
      </div>
    </>
  );
}
