import { Button } from "@nextui-org/button";
import { useState } from "react";
import { IoMdCheckmark, IoMdClipboard } from "react-icons/io";

interface CopyToClipboardProps {
  patentNumbers: string[];
}

export default function CopyToClipboard({ patentNumbers }: CopyToClipboardProps) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    const text = patentNumbers.join(", ");
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <Button
      className="gap-2 font-semibold rounded"
      onPress={copyToClipboard}
      size="sm"
      endContent={copied ? <IoMdCheckmark size={20} /> : <IoMdClipboard size={20} />}
    >
      Copy All Patent Numbers
    </Button>
  );
}
